<template>
  <div class="login d-flex just justify-content-center">
    <form class="login-form w-login-form" @submit.prevent="onLogin">
      <div class="form-group">
        <b-form-input v-model="login" placeholder="Email"></b-form-input>
      </div>

      <div class="mb-1 form-group">
        <b-form-input v-model="password" type="password" placeholder="Password"></b-form-input>
      </div>

      <div class="mb-1 text-right form-group">
        <a href="#" @click.prevent="forgotPassword()">Забыли пароль?</a>
      </div>

      <div class="form-group">
        <b-button variant="success" :disabled="!this.login || !this.password" type="submit">Войти</b-button>
      </div>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      login: "",
      password: ""
    };
  },
  methods: {
    ...mapActions(["logIn"]), 
    ...mapActions("manufacturers", ["manufacturerVerify"]),
    ...mapActions("partners", ["dealerVerify"]),
    async onLogin() {
      let user = {
        userName: this.login,
        password: this.password
      };
      await this.logIn(user).then(() => {
        console.log(this.isAuth, this.isAdmin);
        if (this.isAuth) {
          if (!this.userIsAgree){
            this.$router.push("/checkagree");
          }
          else {
            if (this.isAdmin) this.$router.push("/users");
            else if (this.isManager || this.isLeader || this.isCurator) this.$router.push(`/manufacturers`);
            else if (this.isManufacturer) this.$router.push("/certificates");
            else if (this.isDealer) this.$router.push("/offices");
            
            else this.$router.push("/");
          }          
        }
      });
    },
    forgotPassword() {
      this.$router.push("/forgotpassword");
    }
  },
  computed: {
    ...mapState({ userIsAgree: state => state.auth.user.isAgree }),
    ...mapGetters([
      "userRole",
      "isAuth",
      "isAdmin",
      "isLeader",
      "isManager",
      "isCurator",
      "isManufacturer",
      "isDealer"
    ]),
    ...mapState("manufacturers", {
      isManufacturerConfirmed: state => state.confirmed
    }),
    ...mapState("partners", {
      isDealerConfirmed: state => state.confirmed
    })      
  } 
  
};
</script>

<style lang="scss">
.login {
  &-form {
    position: relative;
    top: 20vh;
  }
}
.w-login-form{
  width: 25%;
  min-width: 15em;
  max-width: 25em;
}
</style>
